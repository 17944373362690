@import '@/styles/mixins.scss';

.wrap {
    @include flex-row;
    gap: 40px;
    justify-content: center;
    background: var(--colors-gray-light-95, #fafafb);
    padding: 70px 70px 0 100px;
    height: 336px;
    overflow: hidden;
}

.content {
    @include flex-column;
    gap: 32px;
    padding-bottom: 70px;
}

.header-wrap {
    @include flex-column;
    gap: 16px;
}

.subtitle {
    @include headline-6;
    color: var(--colors-primary);
}

.title {
    @include headline-2;
    color: var(--colors-primary-dark-90);
    margin: 0;
}

.description {
    @include text-L;
    color: var(--colors-gray-dark-20);
    margin: 0;
}

.form {
    @include flex-row;
    gap: 16px;
    height: 45px;
}

.submit {
    @include flex-center;
    @include cta-primary;
    height: 100%;
    border-radius: 8px;
    background: var(--colors-primary-dark-0, #1570ef);
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.12);
    padding: 12px 24px;
}

.input {
    width: 264px;
    gap: 10px;
    border-radius: 8px;
    border: var(--counter, 1px) solid var(--colors-gray-light-70, #e0e3e8);
    background: var(--colors-mono-white-opacity-100, #fff);
    padding: 0 14px;
    line-height: 44px;
    margin-bottom: 0;
    outline: none;
    transition: 150ms ease-out box-shadow;

    &::placeholder {
        @include text-M;
        color: var(--colors-gray-light-50);
    }

    &:focus {
        border: 1px solid #84caff;
        -webkit-box-shadow: 0px 0px 0px 4px #d1e9ff;
        -moz-box-shadow: 0px 0px 0px 4px #d1e9ff;
        box-shadow: 0px 0px 0px 4px #d1e9ff;
    }

    &:invalid:not(:placeholder-shown) {
        border: 1px solid #fda29b;
    }

    &:invalid:not(:placeholder-shown):focus {
        -webkit-box-shadow: 0px 0px 0px 4px #fee4e2;
        -moz-box-shadow: 0px 0px 0px 4px #fee4e2;
        box-shadow: 0px 0px 0px 4px #fee4e2;
    }
}

.email-graphic {
    width: 379.092px;
    height: 379.092px;
    transform: rotate(6.273deg);
    background-image: url('/images/email-graphic.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background: linear-gradient(0deg, #1570ef 0%, #1570ef 100%),
        url('/images/email-graphic.png') var(--colors-gray-light-95) 50% / contain no-repeat;
    background-blend-mode: hue, normal;
}

@media screen and (max-width: 1280px) {
    .wrap {
        padding: 100px 60px 0 60px;
        gap: 60px;
        height: 406px;
    }

    .content {
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 960px) {
    .wrap {
        @include flex-column;
        gap: 0;
        height: 664px;
    }

    .email-graphic {
        margin: auto;
    }
}

@media screen and (max-width: 764px) {
    .wrap {
        padding: 60px 40px 0 40px;
        height: 580px;
    }
}

@media screen and (max-width: 524px) {
    .wrap {
        padding: 60px 32px 0 32px;
    }

    .form {
        justify-content: flex-end;
    }

    .email-graphic {
        width: 270px;
        height: 270px;
    }

    .input {
        width: 100%;
    }
}
