@import '@/styles/mixins.scss';

.wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 24px;
    margin-top: 48px;
}

.button-wrapper {
    @include flex-center;
    margin-top: 48px;
}

@media screen and (max-width: 1280px) {
    .wrapper {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (max-width: 764px) {
    .wrapper {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-template-rows: repeat(2, minmax(165px, max-content));

        > div:nth-child(4),
        > div:nth-child(5) {
            display: none;
        }
    }
}
