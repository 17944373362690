@import '@/styles/mixins.scss';

.rich-text {
    @include text-L;
    display: block;
    font-weight: 500;
    color: #667085;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        display: inline-block;
        color: var(--colors-primary-dark-90);
        margin-bottom: 16px;
    }

    h1 {
        @include hero-headline;
    }
    h2 {
        @include headline-1;
    }
    h3 {
        @include headline-2;
    }
    h4 {
        @include headline-3;
    }

    a {
        color: var(--colors-primary);
        text-decoration: none;
        border-bottom: 1px solid var(--colors-primary);
    }

    img {
        max-width: 60%;
        margin-bottom: 32px;
    }
}
