@import '@/styles/mixins.scss';

.content-wrap {
    padding: 70px;

    &.wide {
        padding: 70px 0;
    }
}

.posts {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 32px;
    margin: 52px 0;
}

.button-wrap {
    @include flex-center;
}

@media screen and (max-width: 834px) {
    .content-wrap {
        padding: 0 20px;
    }
}

@media screen and (max-width: 500px) {
    .posts {
        grid-template-columns: 1fr;
    }
}
