@import '@/styles/mixins.scss';

.section-wrapper {
    margin: 0;
    padding: 0;

    position: relative;

    &:last-of-type {
        margin-bottom: 80px;
    }
}

.wrapper {
    padding-top: 0;
    padding-bottom: 0;
}

.widget {
    min-height: 140px;
    opacity: 0;
    animation: fadeIn 1s ease-out 1s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media screen and (max-width: 1280px) {
}

@media screen and (max-width: 960px) {
}

@media screen and (max-width: 764px) {
}

@media screen and (max-width: 524px) {
}
