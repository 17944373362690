@import '@/styles/mixins.scss';

.section-wrap {
    padding: 80px 70px;
}

.wrapper {
    display: grid;
    gap: 60px;
    grid-template-rows: minmax(0, 1fr);

    &.columns-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    &.columns-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

@media screen and (max-width: 960px) {
    .wrapper {
        grid-template-columns: minmax(0, 1fr) !important;
        gap: 40px;
    }

    .section-wrap {
        padding: 80px 60px;
    }
}

@media screen and (max-width: 764px) {
    .section-wrap {
        padding: 60px 40px;
    }
}

@media screen and (max-width: 524px) {
    .wrapper {
        gap: 32px;
    }

    .section-wrap {
        padding: 60px 20px;
    }
}
