@import '@/styles/mixins.scss';

.feature {
    @include flex-column;
    gap: 16px;
    padding: 20px 20px 20px 0;
    border-left: 1px solid var(--colors-primary-light-90);
}

.icon-wrapper {
    @include flex-center;
    width: 48px;
    height: 48px;
    border: 8px solid var(--colors-primary-light-95);
    background-color: var(--colors-primary-light-90);
    border-radius: 100px;
    margin-left: 20px;
}

.icon {
    width: 20px;
    height: 20px;
    stroke: var(--colors-primary);
}

.title {
    @include headline-3;
    color: var(--colors-primary-dark-90);
    margin: 0;
    padding-left: 21px;
    border-left: 2px solid var(--colors-primary);
    margin-left: -1px;
}

.description {
    @include text-L;
    margin: 0;
    margin-left: 20px;
    color: var(--colors-gray);
}
